import type { AllHTMLAttributes, FC } from 'react';

export const FamilyKids: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.7486 92.7625C71.1503 92.7625 79.5825 101.225 79.5825 111.664L79.5825 128L41.9148 128L41.9148 111.664C41.9148 101.225 50.347 92.7625 60.7486 92.7625Z"
      fill="currentColor"
    />
    <circle cx="61.0524" cy="76.6626" r="13.6697" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.422 21.264C111.422 33.0078 101.902 42.528 90.158 42.528C78.4142 42.528 68.894 33.0078 68.894 21.264C68.894 9.52023 78.4142 0 90.158 0C101.902 0 111.422 9.52023 111.422 21.264ZM51.8828 42.528C51.8828 52.2586 43.9946 60.1468 34.264 60.1468C24.5334 60.1468 16.6453 52.2586 16.6453 42.528C16.6453 32.7975 24.5334 24.9093 34.264 24.9093C43.9946 24.9093 51.8828 32.7975 51.8828 42.528ZM115.675 72.2977C115.675 58.2051 104.251 46.7808 90.158 46.7808C81.1891 46.7808 73.3011 51.4081 68.7508 58.4055C75.7666 61.4342 80.6769 68.4147 80.6769 76.542C80.6769 82.1637 78.3275 87.2368 74.557 90.8328C81.1859 95.3249 85.5373 102.932 85.5373 111.543V124.85H115.675V72.2977ZM43.5629 67.1428C42.0473 69.9376 41.1866 73.1393 41.1866 76.542C41.1866 82.0399 43.4337 87.013 47.0595 90.593C40.2284 95.0511 35.7187 102.776 35.7187 111.543L35.7187 125.154L13 125.154L13 86.2712C13 74.5274 22.5202 65.0072 34.264 65.0072C37.5986 65.0072 40.7539 65.7747 43.5629 67.1428Z"
      fill="currentColor"
    />
  </svg>
);
