import type { AllHTMLAttributes, FC } from 'react';

export const FeedbackB: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 64 64" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 59.7333C47.3167 59.7333 59.7333 47.3167 59.7333 32C59.7333 16.6833 47.3167 4.26667 32 4.26667C16.6833 4.26667 4.26667 16.6833 4.26667 32C4.26667 47.3167 16.6833 59.7333 32 59.7333ZM32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5912 49.0669C23.1518 49.0669 15.7865 44.3843 14.0784 37.933C13.901 37.2694 14.0275 36.575 14.4275 36.0315C14.8162 35.5034 15.4077 35.2002 16.0501 35.2002H47.1351C47.7754 35.2002 48.3909 35.5137 48.7824 36.0403C49.1626 36.5523 49.2912 37.1976 49.1351 37.8114C47.4849 44.3331 40.1069 49.0669 31.5912 49.0669ZM31.604 40.534C36.2419 40.534 40.4947 39.3328 43.8693 37.334H19.3359C22.7105 39.3328 26.9641 40.534 31.604 40.534ZM25.7344 46.1704C27.2238 44.6722 29.3258 43.7334 31.6641 43.7334C33.9339 43.7334 35.9858 44.616 37.4677 46.038C35.5823 46.6103 33.5358 46.9334 31.3873 46.9334C29.4011 46.9334 27.4986 46.6615 25.7344 46.1704Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.0222 27.7338C46.6861 27.7338 46.365 27.6298 46.0947 27.4351C45.7274 27.1683 45.3345 27.0338 44.9263 27.0338C44.525 27.0338 44.1358 27.1683 43.7708 27.4343C43.5005 27.6298 43.1795 27.7338 42.8418 27.7338C42.1891 27.7338 41.5878 27.3526 41.31 26.7618C40.8874 25.8753 40.6641 24.838 40.6641 23.7635C40.6641 20.6588 42.576 18.1338 44.9263 18.1338C47.2827 18.1338 49.1992 20.6588 49.1992 23.7635C49.1992 24.8365 48.9758 25.8738 48.5525 26.7641C48.2739 27.3526 47.6734 27.7338 47.0222 27.7338Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3572 27.7338C20.0203 27.7338 19.6992 27.6298 19.4289 27.4343C19.0632 27.1683 18.6702 27.0338 18.2621 27.0338C17.8586 27.0338 17.4701 27.1676 17.1067 27.4336C16.8364 27.6298 16.5153 27.7338 16.1769 27.7338C15.5257 27.7338 14.9245 27.3526 14.6451 26.7641C14.2234 25.8723 14 24.8357 14 23.7635C14 20.6588 15.9126 18.1338 18.2621 18.1338C20.6177 18.1338 22.5333 20.6588 22.5333 23.7635C22.5333 24.8335 22.3107 25.8709 21.8897 26.7626C21.6081 27.3533 21.0069 27.7338 20.3572 27.7338Z"
      fill="currentColor"
    />
  </svg>
);
