import type { AllHTMLAttributes, FC } from 'react';

export const CarRental: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 152 182" {...props}>
    <path
      d="M11.37,181H23.58A4.42,4.42,0,0,0,28,176.59V158.33H7v18.26A4.41,4.41,0,0,0,11.37,181Z"
      fill="currentColor"
    />
    <path
      d="M140.62,181a4.42,4.42,0,0,0,4.43-4.41V158.33H124v18.26a4.4,4.4,0,0,0,4.41,4.41Z"
      fill="currentColor"
    />
    <path
      d="M144.4,106.53c-11.65-27.85-.21-.44-12.45-29.3-2.5-5.86-8-11-13.74-11H33.78c-5.75,0-11.24,5.1-13.72,11-12.25,28.86-.81,1.45-12.45,29.3-7,16.66-8.52,13.2-4.2,37.81C4.73,151.82,6,153,13.88,153H138.11c7.87,0,9.16-1.17,10.48-8.65C152.91,119.73,151.36,123.19,144.4,106.53ZM28.82,78.11C29.49,76.59,30.94,75,32,75H120c1.1,0,2.55,1.58,3.22,3.1L134,102.75H18ZM19.2,135.32a11.73,11.73,0,1,1,11.73-11.73A11.73,11.73,0,0,1,19.2,135.32Zm113.61,0a11.73,11.73,0,1,1,11.73-11.73A11.73,11.73,0,0,1,132.81,135.32Z"
      fill="currentColor"
    />
    <path
      d="M117,13.76H67.56a22.85,22.85,0,1,0-1.35,22.59H75L84,28l6.6,6,6.6-6,6.59,6,6.61-6,6.6,6,10.81-10.1ZM36.05,29.55A5.54,5.54,0,1,1,41.59,24,5.54,5.54,0,0,1,36.05,29.55Z"
      fill="currentColor"
    />
  </svg>
);
