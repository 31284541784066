import type { AllHTMLAttributes, FC } from 'react';

export const FeedbackD: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 119 119" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.5 111.067C87.9795 111.067 111.067 87.9795 111.067 59.5C111.067 31.0205 87.9795 7.93333 59.5 7.93333C31.0205 7.93333 7.93333 31.0205 7.93333 59.5C7.93333 87.9795 31.0205 111.067 59.5 111.067ZM59.5 119C92.3609 119 119 92.3609 119 59.5C119 26.6391 92.3609 0 59.5 0C26.6391 0 0 26.6391 0 59.5C0 92.3609 26.6391 119 59.5 119Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.0496 79.3333H30.9336C29.184 79.3333 27.7666 78.0022 27.7666 76.3576C27.7666 74.7159 29.184 73.3833 30.9336 73.3833H90.0496C91.7992 73.3833 93.2166 74.7159 93.2166 76.3576C93.2166 78.0022 91.7992 79.3333 90.0496 79.3333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.6665 48.5924C39.6665 52.4245 36.5581 55.5333 32.7249 55.5333C28.8917 55.5333 25.7832 52.4245 25.7832 48.5924C25.7832 44.7588 28.8917 41.65 32.7249 41.65C36.5581 41.65 39.6665 44.7588 39.6665 48.5924Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.1997 48.5924C95.1997 52.4245 92.0913 55.5333 88.2581 55.5333C84.4249 55.5333 81.3164 52.4245 81.3164 48.5924C81.3164 44.7588 84.4249 41.65 88.2581 41.65C92.0913 41.65 95.1997 44.7588 95.1997 48.5924Z"
      fill="currentColor"
    />
  </svg>
);
