import type { AllHTMLAttributes, FC } from 'react';

export const Clock: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 58 58" {...props}>
    <path
      fill="currentColor"
      d="M29 0.5C23.3632 0.5 17.8531 2.1715 13.1663 5.30312C8.47946 8.43474 4.82654 12.8858 2.66945 18.0935C0.512349 23.3012 -0.0520464 29.0316 1.04763 34.5601C2.14731 40.0885 4.86167 45.1667 8.84747 49.1525C12.8333 53.1383 17.9115 55.8527 23.4399 56.9524C28.9684 58.0521 34.6988 57.4877 39.9065 55.3306C45.1142 53.1735 49.5653 49.5206 52.6969 44.8338C55.8285 40.147 57.5 34.6368 57.5 29C57.5 21.4413 54.4973 14.1922 49.1526 8.84746C43.8078 3.50267 36.5587 0.5 29 0.5V0.5ZM43.4 41.75C42.9692 42.2061 42.3769 42.4753 41.75 42.5C41.4716 42.513 41.1937 42.4661 40.935 42.3626C40.6762 42.2591 40.4426 42.1014 40.25 41.9L27.5 30.65C27.044 30.2192 26.7747 29.6269 26.75 29V11C26.75 10.4033 26.9871 9.83097 27.409 9.40901C27.831 8.98705 28.4033 8.75 29 8.75C29.5967 8.75 30.169 8.98705 30.591 9.40901C31.013 9.83097 31.25 10.4033 31.25 11V27.95L43.25 38.6C43.4703 38.7747 43.6533 38.9919 43.7881 39.2386C43.923 39.4854 44.0068 39.7567 44.0348 40.0365C44.0628 40.3163 44.0343 40.5989 43.951 40.8674C43.8678 41.136 43.7314 41.3851 43.55 41.6L43.4 41.75Z"
    ></path>
  </svg>
);
