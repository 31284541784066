import type { AllHTMLAttributes, FC } from 'react';

export const AirlineLounge: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 26 26" {...props}>
    <circle fill="transparent" strokeWidth="1" cx="13" cy="13" r="12" />
    <path
      fill="currentColor"
      d="M7.5,8.5A1.3,1.3,0,0,0,9.3,9a1.3,1.3,0,0,0,.5-1.8A1.3,1.3,0,0,0,8,6.7,1.2,1.2,0,0,0,7.5,8.5Z"
    />
    <path
      fill="currentColor"
      d="M10.4,14.8,6.1,8.6c-.2-.3-.8-.2-.5.4l4.9,10.5h5l2-5.3c.2-.5-.2-1-1-.8Z"
    />
    <path
      fill="currentColor"
      d="M9.6,11.9l1.2,1.8,5.3-1.2-.4-1.4-2.6.8L12,10.2a1.5,1.5,0,0,0-1.5-.6,1.3,1.3,0,0,0-1.1,1.7A1.4,1.4,0,0,0,9.6,11.9Z"
    />
    <polygon
      fill="currentColor"
      points="18.1 15.6 17.5 17.1 19.1 19.5 20.5 18.6 18.1 15.6"
    />
  </svg>
);
