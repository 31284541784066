import type { AllHTMLAttributes, FC } from 'react';

export const Walking: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M26.8 13.2C27.6331 13.1523 28.4196 12.7998 29.0097 12.2097C29.5998 11.6196 29.9523 10.8331 30 10C30 9.15131 29.6628 8.33737 29.0627 7.73726C28.4626 7.13714 27.6487 6.8 26.8 6.8C25.9513 6.8 25.1373 7.13714 24.5372 7.73726C23.9371 8.33737 23.6 9.15131 23.6 10C23.6477 10.8331 24.0002 11.6196 24.5902 12.2097C25.1803 12.7998 25.9668 13.1523 26.8 13.2Z"
      fill="currentColor"
    />
    <path
      d="M33.1 23.4L30.2 21.4L28.1 17.8L27.3 16.4V16.2L27 15.9C26.3631 15.1763 25.4862 14.7069 24.5308 14.5783C23.5753 14.4497 22.6056 14.6704 21.8 15.2L20.8 15.8L16.7 18.7C16.4212 18.8522 16.2078 19.1012 16.1 19.4L14.3 25C14.2212 25.1827 14.1823 25.38 14.1859 25.5789C14.1894 25.7778 14.2352 25.9737 14.3204 26.1535C14.4056 26.3333 14.5281 26.4928 14.6797 26.6216C14.8314 26.7503 15.0088 26.8452 15.2 26.9H15.7C16.0077 26.8971 16.3072 26.7996 16.5576 26.6207C16.8081 26.4418 16.9974 26.1902 17.1 25.9L18.5 20.9L20.5 19.5C20.7 19.4 20.8 19.5 20.8 19.7L19.9 24.9C19.8429 25.257 19.8672 25.6224 19.9711 25.9687C20.075 26.315 20.2558 26.6334 20.5 26.9L19.2 33.7L15 40.3C14.7495 40.6501 14.6411 41.0822 14.6968 41.5091C14.7525 41.936 14.9681 42.3259 15.3 42.6C15.6213 42.8349 16.0028 42.9736 16.4 43C16.6966 43.0006 16.9887 42.9278 17.2504 42.7882C17.5121 42.6487 17.7352 42.4466 17.9 42.2L22.2 35.3L22.5 34.7L23.6 30.6L26.5 34L27.7 41.6C27.7884 41.9886 28.0032 42.337 28.3109 42.5903C28.6186 42.8437 29.0016 42.9878 29.4 43H29.7C29.9238 42.9752 30.1406 42.9062 30.3375 42.797C30.5345 42.6878 30.7078 42.5405 30.8473 42.3637C30.9869 42.1869 31.0899 41.9842 31.1505 41.7672C31.211 41.5503 31.2278 41.3235 31.2 41.1L29.9 33.2C29.8 33 29.8 32.7 29.6 32.6L25.6 25.9L26.3 21.1C26.4 21.1 26.4 21 26.5 21.1L27.7 23.3C27.8127 23.5131 27.9869 23.6873 28.2 23.8L31.6 26L32.4 26.2C32.7978 26.2 33.1793 26.042 33.4606 25.7607C33.742 25.4794 33.9 25.0978 33.9 24.7C33.8772 24.4359 33.793 24.1809 33.6541 23.9552C33.5152 23.7294 33.3254 23.5393 33.1 23.4Z"
      fill="currentColor"
    />
  </svg>
);
