import type { AllHTMLAttributes, FC } from 'react';

export const Notifications: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 237 202" {...props}>
    <defs>
      <clipPath id="notification-a">
        <path
          fill="none"
          d="M185.4373 103.99505a4.65915 4.65915 0 00-.00413 6.61572 18.6681 18.6681 0 01-.0021 26.45664 4.65925 4.65925 0 00.0021 6.61572 4.712 4.712 0 006.64328 0 27.99514 27.99514 0 000-39.686 4.70885 4.70885 0 00-6.63915-.00208zm19.9297-13.22518a4.71186 4.71186 0 00-6.64317.002 4.65538 4.65538 0 00-.00211 6.60953 37.32636 37.32636 0 010 52.91122 4.65727 4.65727 0 00.00412 6.61572 4.71193 4.71193 0 006.64326-.00207 46.66322 46.66322 0 00-.0021-66.1364zM42.14882 97.38764a4.65918 4.65918 0 00-.00207-6.61576 4.71193 4.71193 0 00-6.64323 0 46.66142 46.66142 0 000 66.13647 4.71 4.71 0 006.64323 0 4.65788 4.65788 0 00.00207-6.61158 37.3276 37.3276 0 010-52.90913zm13.29062 6.60947a4.71191 4.71191 0 00-6.64323 0 27.99509 27.99509 0 00-.00207 39.686 4.70936 4.70936 0 006.64114 0 4.65852 4.65852 0 00.00416-6.61365 18.66878 18.66878 0 01.00208-26.45871 4.65853 4.65853 0 00-.00208-6.61364zm65.001-49.05135c-6.45211 0-11.7638 2.28655-12.46592 8.50946a45.22456 45.22456 0 00-32.93362 43.44867c0 33.26877-14.98155 62.502-14.98155 67.35623-.01868 6.11126 13.94914 9.87188 41.26365 12.483a19.914 19.914 0 0038.18714 0c27.3249-2.61114 41.301-6.37176 41.301-12.483 0-4.85428-14.96907-34.08746-14.96907-67.35623a45.23445 45.23445 0 00-32.944-43.44867c-.7042-6.23114-6.01379-8.50946-12.45761-8.50946z"
        />
      </clipPath>
      <clipPath id="notification-b">
        <path
          fill="none"
          d="M-323.9531-2838.86867h2169.06561V1017.2472H-323.9531z"
        />
      </clipPath>
      <clipPath id="notification-c">
        <path
          fill="none"
          d="M177.64623 41.98228h10.24552V52.2278h-10.24552zm0-30.73676h10.24552v25.61394h-10.24552zm5.12281 51.2278a30.73666 30.73666 0 10-30.73666-30.73666A30.7479 30.7479 0 00182.769 62.47332z"
        />
      </clipPath>
      <clipPath id="notification-d">
        <path fill="none" d="M151.0321 1h63.26441v62.2602H151.0321z" />
      </clipPath>
    </defs>
    <g clipPath="url(#notification-a)">
      <g clipPath="url(#notification-b)">
        <path
          fill="currentColor"
          d="M16.74193 49.92478h207.38699v156.09621H16.74193z"
        />
      </g>
    </g>
    <g clipPath="url(#notification-c)">
      <g clipPath="url(#notification-b)">
        <g clipPath="url(#notification-clip-path-5)">
          <g clipPath="url(#notification-d)">
            <path
              fill="currentColor"
              d="M147.0114-4.02098h71.51529V67.4943H147.0114z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
