import type { AllHTMLAttributes, FC } from 'react';

export const Questions: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    viewBox="0 0 48 41"
    className="button__icon"
    {...props}
  >
    <path
      fill="currentColor"
      d="M26.75,3.1h1.32V44.39H26.75Z"
      transform="translate(0 -3.1)"
    ></path>
    <path
      fill="currentColor"
      d="M.52,12a20.52,20.52,0,0,1,7.87-2c2.89,0,5.77,1.31,5.77,4.59s-1.83,4.19-3.8,6.29a13.75,13.75,0,0,0-3.67,9.58H13c-.65-6.69,8.66-9.58,8.66-16.66s-5.77-9-11.67-9A35.85,35.85,0,0,0,0,6.37Z"
      transform="translate(0 -3.1)"
    ></path>
    <path
      fill="currentColor"
      d="M6.56,36H13v6.18H6.56ZM38.3,5.06h6.42v6.18H38.3Z"
      transform="translate(0 -3.1)"
    ></path>
    <path
      fill="currentColor"
      d="M48,42.44V39.16H44.85V16.34H35.41v3.28H38.3V39.16H35.41v3.28Z"
      transform="translate(0 -3.1)"
    ></path>
  </svg>
);
