import type { AllHTMLAttributes, FC } from 'react';

export const Star: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 110 104" {...props}>
    <path
      fill="currentColor"
      d="M55.235 0l12.85 39.549h41.584L76.027 63.99l12.85 39.549-33.642-24.443-33.642 24.443 12.85-39.549L.801 39.549h41.584L55.235 0z"
    />
  </svg>
);
