import type { AllHTMLAttributes, FC } from 'react';

export const PrayerRooms: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109 182" {...props}>
    <path
      d="M14 114h7c-1.7 6.2 1.7 16.6 6.8 20.3C59 159 67.9 160.9 67.9 160.9H1v20c45.8.2 70.9 0 73.6 0 10.1-.1 15.7-11.9 13.3-20-3-10.5-37.3-45.4-39.9-46.9h7l-.5-39.7c7 6.1 13.4 13.3 20.1 13.3s33.4-20 33.4-20l-6.7-13.3S81.2 67.6 74.6 67.6 59.9 48.7 54.5 41s-26.7-13.3-31.9 7.6S14 114 14 114z"
      fillRule="evenodd"
      fill="currentColor"
    />
    <ellipse cx="68" cy="18" rx="16.5" ry="17" fill="currentColor" />
  </svg>
);
