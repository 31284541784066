import type { AllHTMLAttributes, FC } from 'react';

export const Plus: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 52 52" fill="none" {...props}>
    <path
      d="M51.6546 23.0928H0.507782V28.7758H51.6546V23.0928Z"
      fill="currentColor"
    />
    <path
      d="M28.9227 0.36084H23.2397V51.5077H28.9227V0.36084Z"
      fill="currentColor"
    />
  </svg>
);
