import type { AllHTMLAttributes, FC } from 'react';

export const FeedbackA: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 119 119" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.5 111.067C87.9795 111.067 111.067 87.9795 111.067 59.5C111.067 31.0205 87.9795 7.93333 59.5 7.93333C31.0205 7.93333 7.93333 31.0205 7.93333 59.5C7.93333 87.9795 31.0205 111.067 59.5 111.067ZM59.5 119C92.3609 119 119 92.3609 119 59.5C119 26.6391 92.3609 0 59.5 0C26.6391 0 0 26.6391 0 59.5C0 92.3609 26.6391 119 59.5 119Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.9674 63.2251C40.5182 75.1823 59.5387 89.25 59.5387 89.25C61.3224 87.946 78.5255 75.1468 85.0476 63.1713C93.3426 47.9377 76.9577 26.3063 59.4883 44.8127C41.9937 26.3405 25.6471 48.0037 33.9674 63.2251Z"
      fill="currentColor"
    />
  </svg>
);
