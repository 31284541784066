import { createIconProvider } from '../../../../lib/create-icon-provider';
import type { IconLibrary } from '../../../../types/icon';
import { IconName } from '../../../../types/icon';
import { Agent } from './agent';
import { AirlineLounge } from './airline-lounge';
import { Arrival } from './arrival';
import { ArrowLeft } from './arrow-left';
import { ArrowRight } from './arrow-right';
import { Attention } from './attention';
import { BabyChild } from './baby-child';
import { Baggage } from './baggage';
import { BorderControl } from './border-control';
import { Bus } from './bus';
import { Buses } from './buses';
import { CallEnd } from './call-end';
import { CarRental } from './car-rental';
import { CashChange } from './cash-change';
import { Checkmark } from './checkmark';
import { ChevronUp } from './chervron-up';
import { ChevronDown } from './chevron-down';
import { ChevronRight } from './chevron-right';
import { Clock } from './clock';
import { Close } from './close';
import { Customs } from './customs';
import { Departure } from './departure';
import { Drink } from './drink';
import { Error } from './error';
import { Exit } from './exit';
import { FaceMask } from './face-mask';
import { Facebook } from './facebook';
import { FamilyKids } from './family-kids';
import { Faq } from './faq';
import { Feedback } from './feedback';
import { FeedbackA } from './feedback-a';
import { FeedbackB } from './feedback-b';
import { FeedbackC } from './feedback-c';
import { FeedbackD } from './feedback-d';
import { FeedbackE } from './feedback-e';
import { FlightInfo } from './flight-info';
import { FloorSwitch } from './floor-switch';
import { Food } from './food';
import { Football } from './football';
import { Globe } from './globe';
import { Gps } from './gps';
import { Gym } from './gym';
import { Headset } from './headset';
import { Home } from './home';
import { Hotel } from './hotel';
import { Info } from './info';
import { Language } from './language';
import { Lockers } from './lockers';
import { LostFound } from './lost-found';
import { LuggageClaim } from './luggage-claim';
import { MGates } from './m-gates';
import { MapPin } from './map-pin';
import { Medical } from './medical';
import { Min } from './min';
import { MoonLantern } from './moon-lantern';
import { MoveToMobile } from './move-to-mobile';
import { MultiDayTickets } from './multi-day-tickets';
import { NoSmoking } from './no-smoking';
import { Notifications } from './notifications';
import { Pause } from './pause';
import { Pets } from './pets';
import { Phone } from './phone';
import { Plane } from './plane';
import { PlaneSpotting } from './plane-spotting';
import { Play } from './play';
import { Plus } from './plus';
import { Pod } from './pod';
import { PrayerRooms } from './prayer-rooms';
import { PrayerRoomsFemale } from './prayer-rooms-female';
import { PrayerRoomsMale } from './prayer-rooms-male';
import { PrmFlyRight } from './prm-fly-right';
import { PrmInfo } from './prm-info';
import { PrmPhone } from './prm-phone';
import { PrmThumbsUp } from './prm-thumbs-up';
import { PublicTransport } from './public-transport';
import { PublicTransportInfo } from './public-transport-info';
import { Questions } from './questions';
import { Refresh } from './refresh';
import { Relaxation } from './relaxation';
import { Restaurant } from './restaurant';
import { Scanner } from './scanner';
import { ScannerNew } from './scanner-new';
import { Search } from './search';
import { Services } from './services';
import { Shop } from './shop';
import { Shuttles } from './shuttles';
import { SmokingAreas } from './smoking-areas';
import { Spa } from './spa';
import { SpecialAssistance } from './special-assistance';
import { Spinner } from './spinner';
import { StairsDown } from './stairs-down';
import { StairsUp } from './stairs-up';
import { Star } from './star';
import { TaxRefund } from './tax-refund';
import { Taxis } from './taxis';
import { TelephoneCall } from './telephone-call';
import { ThumbsDown } from './thumbs-down';
import { ThumbsUp } from './thumbs-up';
import { Toilet } from './toilet';
import { Touch } from './touch';
import { Trains } from './trains';
import { TransferDesks } from './transfer-desks';
import { Transfers } from './transfers';
import { TransportTicket } from './transport-ticket';
import { Valid } from './valid';
import { VideoCall } from './video-call';
import { Volume } from './volume';
import { Walking } from './walking';
import { WeChat } from './we-chat';
import { Whatsapp } from './whatsapp';
import { Wifi } from './wifi';

const lib: IconLibrary = {
  [IconName.AGENT]: Agent,
  [IconName.AIRLINE_LOUNGE]: AirlineLounge,
  [IconName.ARRIVAL]: Arrival,
  [IconName.ARROW_LEFT]: ArrowLeft,
  [IconName.ARROW_RIGHT]: ArrowRight,
  [IconName.ATTENTION]: Attention,
  [IconName.BABY_CHILD]: BabyChild,
  [IconName.BAGGAGE]: Baggage,
  [IconName.BORDER_CONTROL]: BorderControl,
  [IconName.BUSES]: Buses,
  [IconName.BUS]: Bus,
  [IconName.CALL_END]: CallEnd,
  [IconName.CAR_RENTAL]: CarRental,
  [IconName.CASH_CHANGE]: CashChange,
  [IconName.CHECKMARK]: Checkmark,
  [IconName.CHEVRON_DOWN]: ChevronDown,
  [IconName.CHEVRON_RIGHT]: ChevronRight,
  [IconName.CHEVRON_UP]: ChevronUp,
  [IconName.CLOCK]: Clock,
  [IconName.CLOSE]: Close,
  [IconName.CUSTOMS]: Customs,
  [IconName.DEPARTURE]: Departure,
  [IconName.DRINK]: Drink,
  [IconName.ERROR]: Error,
  [IconName.EXIT]: Exit,
  [IconName.FACEBOOK]: Facebook,
  [IconName.FACE_MASK]: FaceMask,
  [IconName.FAMILY_KIDS]: FamilyKids,
  [IconName.FAQ]: Faq,
  [IconName.FEEDBACK]: Feedback,
  [IconName.FEEDBACK_A]: FeedbackA,
  [IconName.FEEDBACK_B]: FeedbackB,
  [IconName.FEEDBACK_C]: FeedbackC,
  [IconName.FEEDBACK_D]: FeedbackD,
  [IconName.FEEDBACK_E]: FeedbackE,
  [IconName.FIRST_NEEDS]: FeedbackE,
  [IconName.FLIGHT_INFO]: FlightInfo,
  [IconName.FLOOR_SWITCH]: FloorSwitch,
  [IconName.FOOD]: Food,
  [IconName.FOOTBALL]: Football,
  [IconName.GLOBE]: Globe,
  [IconName.GPS]: Gps,
  [IconName.GYM]: Gym,
  [IconName.HEADSET]: Headset,
  [IconName.HOME]: Home,
  [IconName.HOTEL]: Hotel,
  [IconName.INFO]: Info,
  [IconName.LANGUAGE]: Language,
  [IconName.LOCKERS]: Lockers,
  [IconName.LOST_FOUND]: LostFound,
  [IconName.LUGGAGE_CLAIM]: LuggageClaim,
  [IconName.M_GATES]: MGates,
  [IconName.MAP_PIN]: MapPin,
  [IconName.MEDICAL]: Medical,
  [IconName.MIN]: Min,
  [IconName.MOON_LANTERN]: MoonLantern,
  [IconName.MOVE_TO_MOBILE]: MoveToMobile,
  [IconName.MULTI_DAY_TICKETS]: MultiDayTickets,
  [IconName.NO_SMOKING]: NoSmoking,
  [IconName.NOTIFICATIONS]: Notifications,
  [IconName.PAUSE]: Pause,
  [IconName.PETS]: Pets,
  [IconName.PHONE]: Phone,
  [IconName.PLANE]: Plane,
  [IconName.PLANE_SPOTTING]: PlaneSpotting,
  [IconName.PLAY]: Play,
  [IconName.PLUS]: Plus,
  [IconName.POD]: Pod,
  [IconName.PRAYER_ROOMS]: PrayerRooms,
  [IconName.PRAYER_ROOMS_FEMALE]: PrayerRoomsFemale,
  [IconName.PRAYER_ROOMS_MALE]: PrayerRoomsMale,
  [IconName.PUBLIC_TRANSPORT]: PublicTransport,
  [IconName.PUBLIC_TRANSPORT_INFO]: PublicTransportInfo,
  [IconName.QUESTIONS]: Questions,
  [IconName.REFRESH]: Refresh,
  [IconName.RELAXATION]: Relaxation,
  [IconName.RESTAURANT]: Restaurant,
  [IconName.SCANNER]: Scanner,
  [IconName.SCANNER_NEW]: ScannerNew,
  [IconName.SEARCH]: Search,
  [IconName.SERVICES]: Services,
  [IconName.SHOP]: Shop,
  [IconName.SHUTTLES]: Shuttles,
  [IconName.SMOKING_AREAS]: SmokingAreas,
  [IconName.SPA]: Spa,
  [IconName.SPECIAL_ASSISTANCE]: SpecialAssistance,
  [IconName.SPINNER]: Spinner,
  [IconName.STAIRS_DOWN]: StairsDown,
  [IconName.STAIRS_UP]: StairsUp,
  [IconName.STAR]: Star,
  [IconName.TAXIS]: Taxis,
  [IconName.TAX_REFUND]: TaxRefund,
  [IconName.TELEPHONE_CALL]: TelephoneCall,
  [IconName.THUMBS_DOWN]: ThumbsDown,
  [IconName.THUMBS_UP]: ThumbsUp,
  [IconName.TOILET]: Toilet,
  [IconName.TOUCH]: Touch,
  [IconName.TRAINS]: Trains,
  [IconName.TRANSFERS]: Transfers,
  [IconName.TRANSFER_DESKS]: TransferDesks,
  [IconName.TRANSPORT_TICKET]: TransportTicket,
  [IconName.VALID]: Valid,
  [IconName.VIDEO_CALL]: VideoCall,
  [IconName.VOLUME]: Volume,
  [IconName.WALKING]: Walking,
  [IconName.WHATSAPP]: Whatsapp,
  [IconName.WE_CHAT]: WeChat,
  [IconName.WIFI]: Wifi,
  [IconName.PRM_THUMBS_UP]: PrmThumbsUp,
  [IconName.PRM_FLY_RIGHT]: PrmFlyRight,
  [IconName.PRM_INFO]: PrmInfo,
  [IconName.PRM_PHONE]: PrmPhone,
};

const provider = createIconProvider(lib);

export default provider;
