import type { AllHTMLAttributes, FC } from 'react';

export const MapPin: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M24 0C20 0 16 2 13 5C10 8 8 12 8 17C8 29 24 48 24 48C24 48 40 29 40 17C40 12 38 8 35 5C32 2 28 0 24 0ZM24 23C21 23 18 20 18 17C18 13 21 11 24 11C27 11 30 13 30 17C30 20 27 23 24 23Z"
      fill="currentColor"
    />
  </svg>
);
