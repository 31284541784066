import type { AllHTMLAttributes, FC } from 'react';

export const Pause: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="43.5" cy="43.5" r="42" stroke="currentColor" strokeWidth="3" />
    <rect x="32" y="25" width="6" height="38" rx="3" fill="currentColor" />
    <rect x="48" y="25" width="6" height="38" rx="3" fill="currentColor" />
  </svg>
);
