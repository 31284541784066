import type { AllHTMLAttributes, FC } from 'react';

export const StairsDown: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor">
      <polygon points="1 4 15 4 15 16 26 16 26 27 38 27 38 39 47 39 47 44 33 44 33 32 21 32 21 21 10 21 10 9 1 9 1 4" />
      <path d="M33.9,5.3,39,10.4l.8.8a4.78,4.78,0,0,1-.1-1V4L42,6.3V15H33.3L31,12.8h7.2l-.8-.8L32.3,6.9l.8-.8Z" />
    </g>
  </svg>
);
