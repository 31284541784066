import type { AllHTMLAttributes, FC } from 'react';

export const BorderControl: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 80 80" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="transparent" d="M0 77.97h76.96V1.01H0z" />
      <path
        d="M0 80h80V0H0v80zm4.6-64.89l2.58 14.6 11.28-1.99-2.58-14.6-11.27 1.99zm32.57 45.78h17.75L39.54 28.26h-2.37c-1.74 0-3.32.81-4.71 2.2l-10.3 10.35c-.73.74-1.13.7-1.77.06l-9.76-9.77-6.02 6.02 13.94 13.94c2.14 2.14 3.6.78 4.92-.2l13.7-10.7v20.73zM39.18 9.5h17.96l.89-2.53-22.8-2.5 3.95 5.03zm0 2.13L33 15.68h6.75c0 4.8 3.89 9.32 8.7 9.32 4.8 0 8.69-4.52 8.69-9.32v-4.04H39.18zM37.17 76h21.92V65.33H37.17V76zM61.2 28.26H43.7L59.1 60.89V40.96h2.12V76h8.55V39.9c0-5.7-3-11.64-8.55-11.64zM5.89 13.8l5.67-1-.73-4.14L5.9 13.8z"
        fill="currentColor"
      />
    </g>
  </svg>
);
