import type { AllHTMLAttributes, FC } from 'react';

export const PublicTransport: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 176 182" {...props}>
    <path
      d="M72.81,101.72c-.07-1.75-.67-10.15-.67-10.15a1.52,1.52,0,0,0-1.36-1.34s-15.5-1.74-23.18-1.74H27.4c-7.51,0-23.18,1.74-23.18,1.74a1.53,1.53,0,0,0-1.36,1.32s-.59,8.38-.66,10.17L1,135v35.49H3.87v8.22c0,.7.51,1.83,1,1.81h6.39c.48,0,1.2-1.11,1.2-1.81v-8.22h50v8.22c0,.7.72,1.81,1.2,1.81h6.41c.48,0,1-1.11,1-1.81v-8.22H74V135Zm-47.93-8.9a1.21,1.21,0,0,1,1.2-1.21H64.56a1.21,1.21,0,0,1,1.2,1.21V99a1.2,1.2,0,0,1-1.2,1.2H26.08a1.2,1.2,0,0,1-1.2-1.2Zm-13.32,68.9A5.76,5.76,0,1,1,17.31,156a5.74,5.74,0,0,1-5.75,5.77Zm51.87,0A5.76,5.76,0,1,1,69.18,156a5.74,5.74,0,0,1-5.75,5.77Zm5.4-26.53H6.27a.93.93,0,0,1-.9-.9L6.44,104a.92.92,0,0,1,.9-.9H67.7a.91.91,0,0,1,.89.9l1.14,30.27A.92.92,0,0,1,68.83,135.19Z"
      fill="currentColor"
    />
    <path
      d="M87.9,181h7.66a2.76,2.76,0,0,0,2.77-2.76h0V166.83H85.13v11.41A2.76,2.76,0,0,0,87.9,181Z"
      fill="currentColor"
    />
    <path
      d="M168.9,181a2.76,2.76,0,0,0,2.76-2.76V166.83H158.48v11.41a2.77,2.77,0,0,0,2.77,2.76Z"
      fill="currentColor"
    />
    <path
      d="M145.94,89H110.26a3,3,0,0,0-3,3h0v10.73a3,3,0,0,0,3,2.95h35.68a3,3,0,0,0,2.95-2.95h0V92a3,3,0,0,0-2.95-3ZM118,94.17h-3.07v8.69h-2.17V94.17H109.7V92.36H118Zm8.79,8.69-.86-2.28h-4.54l-.85,2.28h-2.18l4-10.48h2.53l4,10.48Zm10.36,0-2.28-4.09-2.41,4.09h-2.69l3.57-5.42-3.22-5.11h2.56L135,96l2-3.62h2.55l-3.22,5.11,3.58,5.42Zm7.85,0h-2.15V92.36H145Z"
      fill="currentColor"
    />
    <polygon
      points="122.09 98.77 125.23 98.77 123.66 94.68 122.09 98.77"
      fill="currentColor"
    />
    <path
      d="M171.26,133.6c-7.3-17.4-.13-.27-7.8-18.3-1.56-3.67-5-6.88-8.61-6.88H101.94c-3.61,0-7,3.2-8.6,6.87-7.66,18-.51.9-7.81,18.3-4.36,10.43-5.34,8.27-2.6,23.66.82,4.68,1.63,5.41,6.56,5.41h77.83c4.93,0,5.74-.73,6.57-5.41C176.6,141.86,175.63,144,171.26,133.6ZM98.83,115.94c.42-1,1.33-2,2-2H156c.68,0,1.59,1,2,2l6.76,15.4H92.06Zm-6,35.67a7.34,7.34,0,1,1,7.35-7.33A7.33,7.33,0,0,1,92.8,151.61Zm71.2,0a7.34,7.34,0,1,1,7.35-7.33,7.35,7.35,0,0,1-7.35,7.33Z"
      fill="currentColor"
    />
    <rect fill="currentColor" x="90.09" y="1" width="3.35" height="70" />
    <path
      fill="currentColor"
      d="M47.41,16.17A33.42,33.42,0,0,1,60.63,13c4.69,0,9.71,2,9.71,7.67s-3,7-6.53,10.33a28.8,28.8,0,0,0-6,17.83H68.33C67.33,37.5,82.9,31,82.9,19.33S73,4,63.31,4A63.06,63.06,0,0,0,46.57,6.83Z"
    />
    <rect
      fill="currentColor"
      x="57.45"
      y="57.17"
      width="10.71"
      height="10.33"
    />
    <rect fill="currentColor" x="108.51" y="4.33" width="10.04" height="10" />
    <polygon
      fill="currentColor"
      points="123.57 67.67 123.57 62 118.38 62 118.38 22.67 101.81 22.67 101.81 28.33 106.83 28.33 106.83 62 101.81 62 101.81 67.67 123.57 67.67"
    />
  </svg>
);
