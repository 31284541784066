import type { AllHTMLAttributes, FC } from 'react';

export const ChevronDown: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M18.2 8.60001L12 14.7L5.79999 8.60001L4.39999 10L12 17.5L19.6 10L18.2 8.60001Z"
    />
  </svg>
);
