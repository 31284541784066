import type { AllHTMLAttributes, FC } from 'react';

export const FeedbackE: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 119 119" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.5 111.067C87.9795 111.067 111.067 87.9795 111.067 59.5C111.067 31.0205 87.9795 7.93333 59.5 7.93333C31.0205 7.93333 7.93333 31.0205 7.93333 59.5C7.93333 87.9795 31.0205 111.067 59.5 111.067ZM59.5 119C92.3609 119 119 92.3609 119 59.5C119 26.6391 92.3609 0 59.5 0C26.6391 0 0 26.6391 0 59.5C0 92.3609 26.6391 119 59.5 119Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.5926 83.2998C79.9316 83.2998 79.2719 83.0443 78.7503 82.529C74.2473 78.0687 68.1192 75.4641 61.4964 75.1935C54.1372 74.8972 47.1295 77.5576 42.2583 82.5046C41.178 83.603 39.4647 83.5568 38.4317 82.4094C37.3986 81.2607 37.4408 79.4377 38.5212 78.3433C44.4459 72.3292 52.9072 69.0788 61.7035 69.4445C69.6215 69.7667 76.9859 72.9151 82.4375 78.3162C83.5307 79.401 83.5933 81.2226 82.5744 82.3836C82.0412 82.9912 81.3175 83.2998 80.5926 83.2998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.3777 51.5667C76.6556 51.5667 75.9564 51.2228 75.5974 50.621C75.0817 49.7566 75.4594 48.6897 76.4427 48.2351L90.2734 41.8523C91.2526 41.4001 92.4703 41.731 92.986 42.5954C93.5017 43.4586 93.124 44.5267 92.1407 44.9801L78.31 51.3641C78.0126 51.5007 77.6925 51.5667 77.3777 51.5667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.5713 47.2453C88.1189 50.0042 86.9973 53.4222 84.0612 54.879C81.1277 56.3357 77.4934 55.2784 75.9445 52.5195C74.3969 49.7606 76.1339 48.6724 79.0673 47.2156C82.0021 45.7589 85.0224 44.4864 86.5713 47.2453Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.639 51.5667C39.3242 51.5667 39.0041 51.5007 38.7067 51.3641L24.8757 44.9801C23.8925 44.5267 23.5148 43.4586 24.0305 42.5954C24.5462 41.731 25.7624 41.4001 26.7429 41.8523L40.5739 48.2351C41.5571 48.6897 41.9348 49.7566 41.4191 50.621C41.0601 51.2228 40.3609 51.5667 39.639 51.5667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.4454 47.2453C28.8978 50.0042 30.0194 53.4222 32.9555 54.879C35.889 56.3357 39.5233 55.2784 41.0722 52.5195C42.6198 49.7606 40.8828 48.6724 37.9494 47.2156C35.0133 45.7589 31.9943 44.4864 30.4454 47.2453Z"
      fill="currentColor"
    />
  </svg>
);
