import type { AllHTMLAttributes, FC } from 'react';

export const Taxis: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 186 182" {...props}>
    <path
      d="M13.73,181h15a5.41,5.41,0,0,0,5.42-5.4V153.28H8.31V175.6A5.41,5.41,0,0,0,13.73,181Z"
      fill="currentColor"
    />
    <path
      d="M172.28,181a5.41,5.41,0,0,0,5.41-5.4V153.28h-25.8V175.6a5.41,5.41,0,0,0,5.41,5.4Z"
      fill="currentColor"
    />
    <path
      d="M127.33,1H57.5a5.78,5.78,0,0,0-5.79,5.77v21a5.78,5.78,0,0,0,5.79,5.77h69.83a5.78,5.78,0,0,0,5.79-5.77v-21A5.78,5.78,0,0,0,127.33,1ZM72.65,11.12h-6v17H62.4v-17h-6V7.58H72.65Zm17.2,17-1.67-4.46h-8.9l-1.66,4.46H73.36l7.9-20.5h4.95l7.89,20.5Zm20.28,0-4.45-8-4.73,8H95.68l7-10.6-6.3-10h5l4.47,7.07,3.93-7.07h5l-6.31,10,7,10.6Zm15.36,0h-4.2V7.58h4.2Z"
      fill="currentColor"
    />
    <polygon
      points="80.65 20.12 86.81 20.12 83.73 12.12 80.65 20.12"
      fill="currentColor"
    />
    <path
      d="M176.9,88.27c-14.29-34.06-.25-.53-15.27-35.82C158.57,45.27,151.85,39,144.78,39H41.22c-7.07,0-13.79,6.25-16.84,13.43-15,35.29-1,1.76-15.28,35.82C.55,108.65-1.35,104.42,4,134.54c1.61,9.14,3.2,10.58,12.84,10.58H169.19c9.65,0,11.24-1.44,12.85-10.58C187.35,104.42,185.45,108.65,176.9,88.27ZM35.13,53.7c.82-1.86,2.6-3.8,3.94-3.8H146.93c1.34,0,3.12,1.94,3.94,3.8L164.1,83.84H21.88Zm-11.8,69.79a14.35,14.35,0,1,1,14.38-14.34A14.37,14.37,0,0,1,23.33,123.49Zm139.35,0a14.35,14.35,0,1,1,14.39-14.34A14.36,14.36,0,0,1,162.68,123.49Z"
      fill="currentColor"
    />
  </svg>
);
