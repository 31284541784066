import type { AllHTMLAttributes, FC } from 'react';

export const Plane: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 48 48" {...props}>
    <defs>
      <clipPath id="plane-a">
        <path
          d="M18 8l3.2 12.4h-9.4l-2.9-5H6v5.7c0 .8-.2 2.4.5 3 3.7 2.7 14.7 2 14.7 2L18 38.6h3.3l7.2-12.4c0 .1 6.7 0 6.7 0 2.4 0 6.8-1.2 6.8-2.7s-4.4-3.1-6.8-3.1h-6.7L21.3 8z"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath id="plane-b">
        <path d="M-50.6-180h266.3v473.42H-50.6z" />
      </clipPath>
    </defs>
    <path
      d="M18 8l3.2 12.4h-9.4l-2.9-5H6v5.7c0 .8-.2 2.4.5 3 3.7 2.7 14.7 2 14.7 2L18 38.6h3.3l7.2-12.4c0 .1 6.7 0 6.7 0 2.4 0 6.8-1.2 6.8-2.7s-4.4-3.1-6.8-3.1h-6.7L21.3 8z"
      fillRule="evenodd"
      fill="currentColor"
    />
    <g clipPath="url(#plane-a)">
      <path d="M-50.6-180h266.3v473.42H-50.6z" fill="currentColor" />
      <g clipPath="url(#plane-b)">
        <path d="M5.4 7.4h37.2v31.81H5.4z" fill="currentColor" />
      </g>
    </g>
  </svg>
);
