import type { AllHTMLAttributes, FC } from 'react';

export const Pod: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230 182" {...props}>
    <path
      d="M1 136.7l5.1 3 5.1-3V128l61.3 35.3V178l5.1 3 5.1-3v-14.7l136.1-79.7v8.9l5.1 2.9 5.1-2.9V45.3L152.4 1l-5.1 3v14.7L18.9 94 6.1 86.6 1 89.5zm150.1-109c37.8 22.3 60.5 36.5 60.5 36.5l-38 21.9s-13.2-8.1-59.4-35.6c-2.1-1.3 3.4-9.9 20.5-18.7 5.2-2.7 14.2-5.4 16.4-4.1zM167 90l-84.3 49.7v-8.9L32 101.6l78-46.1z"
      fill="currentColor"
    />
  </svg>
);
