import type { AllHTMLAttributes, FC } from 'react';

export const Headset: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 387 387" {...props}>
    <g fill="currentColor">
      <path d="M385.6,204.7c-2.9-26.4-25-47-51-49.9-11.7-1.2-23.5-.6-35.4-.7s-17.6,5.6-17.6,17.6V324.3c0,9.9-6.2,16.2-16.1,16.2-13.6,0-27.2-.1-40.7,0a6.5,6.5,0,0,1-5-1.6,30.4,30.4,0,1,0,.7,32.3,6.7,6.7,0,0,1,3.3-.7c12.8-.1,25.7-.6,38.5.1,26.9,1.3,48.7-18.2,49.4-45.8h14.8c20.4-.4,36.7-8.9,48.7-25.4,5.6-7.6,9.9-16.1,10.4-25.7C386.3,267.9,386,209.7,385.6,204.7Zm-29.3,61a29.6,29.6,0,0,1-29.2,29.1H311.6V184.6c17.3.1,29.5-1.1,39.4,12.2a28.1,28.1,0,0,1,5.4,16.5C356.4,230.7,356.5,248.2,356.3,265.7Z" />
      <path d="M344.8,106.9c-1.2-2.9-2.4-5.7-3.7-8.5a85.5,85.5,0,0,0-5.3-11.5C308.3,35.7,254.6,1,192.9,1,138.2,1,89.8,28.3,60.4,70.2a79.5,79.5,0,0,0-7.1,12.1,202.8,202.8,0,0,0-13.9,30.1c-5.5,15-7.7,30.7-8.4,46.6a5.8,5.8,0,0,1-2,4.2C13.7,173.5,4.3,187.6,1.6,205.9,1,210.3,1,236.4,1,236.4s0,23.2.7,37.4c4,26.5,24.8,47.4,51.6,50.4,11.8,1.3,23.9.6,35.9.7s16.9-6,16.9-17V171.6c-.1-11.8-5.8-17.5-17.4-17.5H61.3c.7-5,1.1-9.4,1.9-13.8,16.3-90.5,119-137,197.9-89.4,26.2,15.8,44.7,38.2,55.8,66.8,3.2,8.4,11.5,12.2,19.5,9.2S348.2,115.3,344.8,106.9ZM76,184.1V294.8H64.9c-6.3-.1-12.1-.2-17.9-3.4-10.2-5.6-15.8-14.4-16-26.1-.2-17.2-.1-34.4,0-51.7a29.8,29.8,0,0,1,29.7-29.5Z" />
    </g>
  </svg>
);
