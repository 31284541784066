import type { AllHTMLAttributes, FC } from 'react';

export const Attention: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 48 48" {...props}>
    <path
      style={{ fill: 'var(--color-warning, orange)' }}
      d="M24,5A19,19,0,1,0,43,24,19,19,0,0,0,24,5Z"
    />
    <path
      fill="currentColor"
      d="M24,33a2,2,0,1,1-2,2A2,2,0,0,1,24,33ZM22,11h4V29H22Z"
    />
  </svg>
);
