import type { AllHTMLAttributes, FC } from 'react';

export const Spa: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_237_7773)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.7607 78.9566V128H14V63.9972L14 63.9972C14 52.4653 23.3485 43.1169 34.8804 43.1169C44.778 43.1169 53.0673 50.0035 55.2179 59.2466L104.096 65.6801L104.672 77.7991L55.7607 78.9566Z"
        fill="currentColor"
      />
      <circle cx="34" cy="18" r="18" fill="currentColor" />
      <path
        d="M120.831 48.5121C118.964 47.9073 116.016 47.1006 112.675 47C112.086 48.2097 111.398 49.42 110.514 50.5293C107.468 54.5621 103.341 56.9823 100 58.2932C102.849 58.9994 106.583 59.4024 110.415 58.3938C115.524 57.0828 119.455 53.5543 121.518 51.2347C122.402 50.428 122.01 48.9151 120.831 48.5122V48.5121Z"
        fill="currentColor"
      />
      <path
        d="M81.1118 58.394C85.1179 59.4026 89.0215 58.9989 92 58.2935C88.9182 56.9825 84.296 54.6636 81.0086 50.5294C80.0842 49.4202 79.3649 48.2105 78.7487 47C75.3589 47 72.1746 47.8067 70.2229 48.5122C68.9905 48.9152 68.5792 50.4281 69.5035 51.4359C71.5592 53.5544 75.6677 57.0829 81.1118 58.394L81.1118 58.394Z"
        fill="currentColor"
      />
      <path
        d="M100 55C102.699 53.7495 105.998 51.8263 108.396 48.8449C111.694 44.8056 112.694 39.7085 112.994 36.8232C113.094 35.5727 111.895 34.7073 110.695 35.0924C108.796 35.7659 105.998 36.9198 103.299 38.8431C103.598 40.0936 103.698 41.44 103.698 42.7864C103.698 47.787 101.699 52.3073 100 55H100Z"
        fill="currentColor"
      />
      <path
        d="M91.9999 55C90.2642 52.3057 88.0949 47.881 88.0949 42.8784C88.0949 41.5316 88.2031 40.1847 88.5284 38.8379C85.7083 36.9141 82.5621 35.6633 80.5011 35.0861C79.1996 34.7016 77.8975 35.6632 78.0064 36.8174C78.3317 39.7994 79.4168 44.802 82.9966 48.8425C85.6002 51.9212 89.071 53.8451 92 55H91.9999Z"
        fill="currentColor"
      />
      <path
        d="M96.9368 55.207C98.5225 52.7081 101 48.1907 101 43C101 37.81 98.6214 33.2924 96.9368 30.793C96.2427 29.7357 94.7566 29.7357 94.0632 30.793C92.4775 33.2919 90 37.8093 90 43C90 48.19 92.3786 52.7076 94.0632 55.207C94.7566 56.2643 96.2427 56.2643 96.9368 55.207V55.207Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_237_7773">
        <rect width="128" height="128" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);
