import type { AllHTMLAttributes, FC } from 'react';

export const Bus: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 44 56" {...props}>
    <path
      d="M39.646.292H4.354A4.23 4.23 0 00.125 4.52V54.25a1.313 1.313 0 001.167 1.458h4.666a1.31 1.31 0 001.459-1.166v-4.667h29.166v4.375a1.314 1.314 0 001.167 1.458h4.667a1.31 1.31 0 001.458-1.166V4.52a4.23 4.23 0 00-4.23-4.23zM11.792 3.208h20.416v4.375H11.792V3.208zM8.146 45.792a3.646 3.646 0 110-7.292 3.646 3.646 0 010 7.292zm27.708 0a3.646 3.646 0 110-7.293 3.646 3.646 0 010 7.293zM39.5 32.229a1.604 1.604 0 01-1.604 1.604H6.104A1.604 1.604 0 014.5 32.23V12.104A1.604 1.604 0 016.104 10.5h31.792a1.604 1.604 0 011.604 1.604V32.23z"
      fill="currentColor"
    />
  </svg>
);
