import type { AllHTMLAttributes, FC } from 'react';

export const SmokingAreas: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 26 26" {...props}>
    <circle fill="transparent" strokeWidth="1" cx="13" cy="13" r="12" />
    <rect fill="currentColor" x="5" y="13" width="0.5" height="3" />
    <rect fill="currentColor" x="6" y="13" width="0.5" height="3" />
    <rect fill="currentColor" x="7" y="13" width="14" height="3" />
    <path
      fill="currentColor"
      d="M6.5,12.5H6c0-.1,0-2.2,1.7-2.7a5.3,5.3,0,0,1,1.2-.3c1-.2,1.6-.3,1.6-1.5H11c0,1.7-1,1.8-2,2l-1.2.2C6.5,10.7,6.5,12.5,6.5,12.5Z"
    />
    <path
      fill="currentColor"
      d="M5.5,12.5H5C5,12.4,5,9.8,6.6,9S8,8,8,6h.5c0,2,0,2.7-1.6,3.5S5.5,12.5,5.5,12.5Z"
    />
  </svg>
);
