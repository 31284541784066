import type { AllHTMLAttributes, FC } from 'react';

export const Play: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 87 87" {...props}>
    <circle
      cx="43.5"
      cy="43.5"
      r="42"
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 23.095C30 21.2019 31.2866 20.4601 32.9066 21.4585L65.7642 41.7077C67.3695 42.697 67.3841 44.2919 65.7642 45.2901L32.9066 65.5384C31.3013 66.5276 30 65.7977 30 63.9018V23.095Z"
      fill="currentColor"
    />
  </svg>
);
