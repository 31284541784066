import type { AllHTMLAttributes, FC } from 'react';

export const Toilet: FC = (props: AllHTMLAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.0956 13.8788C45.2307 13.8788 46.9621 12.1147 46.9621 9.93939C46.9621 7.76406 45.2307 6 43.0956 6C40.9605 6 39.2291 7.76406 39.2291 9.93939C39.2291 12.1147 40.9605 13.8788 43.0956 13.8788Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.1397 17.6866C48.8335 16.8451 47.8947 16.2424 46.9621 16.2424H39.2291C38.2965 16.2424 37.3577 16.8451 37.0514 17.6866L32.3142 31.9346C32.0358 32.7729 32.3737 33.7404 33.1648 34.106C34.075 34.5267 35.1166 34.05 35.4283 33.1101L37.9214 25.5992C38.0212 25.2991 38.4558 25.3715 38.4558 25.6883V55.9183C38.4558 56.9481 39.1834 57.8872 40.1895 57.9897C41.3471 58.1079 42.3223 57.1852 42.3223 56.0302V37.5151C42.3223 37.0802 42.6687 36.7272 43.0956 36.7272C43.5224 36.7272 43.8689 37.0802 43.8689 37.5151V55.9365C43.8689 56.8575 44.4442 57.7265 45.3235 57.9416C46.5986 58.2528 47.7354 57.2774 47.7354 56.0302V25.6883C47.7354 25.3715 48.1708 25.2991 48.2697 25.5985L50.7629 33.1101C51.0018 33.8286 51.6676 34.273 52.3752 34.2556C53.082 34.273 54.3337 33.4575 53.917 31.9346L49.1397 17.6866Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6698 13.8788C22.8049 13.8788 24.5363 12.1147 24.5363 9.93939C24.5363 7.76406 22.8049 6 20.6698 6C18.5347 6 16.8033 7.76406 16.8033 9.93939C16.8033 12.1147 18.5347 13.8788 20.6698 13.8788Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2577 32.1781L26.701 17.8182C26.4459 16.8412 26.0513 16.2424 24.5363 16.2424C24.5024 16.2424 21.9247 16.2424 16.8033 16.2424C15.2567 16.2424 14.9319 16.8451 14.6257 17.8182C12.627 24.1229 11.1279 28.8515 10.1285 32.0039C9.63264 33.617 10.6826 34.273 11.3902 34.2556C12.097 34.273 12.7636 33.8286 13.0025 33.1101L15.4926 25.6079C15.5908 25.3125 16.0076 25.3337 16.0764 25.6378L16.7059 28.4167C16.7701 28.6995 16.7561 28.9942 16.6657 29.2692L12.0692 43.3194C11.7351 44.3397 12.4806 45.3939 13.5361 45.3939H16.8033V56.3572C16.8033 57.0466 17.2016 57.7108 17.8457 57.9188C18.9137 58.2639 19.8965 57.461 19.8965 56.4242V45.3939H21.4431V56.3572C21.4431 57.0466 21.8414 57.7108 22.4855 57.9188C23.5535 58.2639 24.5363 57.461 24.5363 56.4242V45.3939H27.8035C28.8599 45.3939 29.6046 44.3405 29.2713 43.3202C26.5923 35.1314 25.0563 30.4478 24.6631 29.2692C24.5363 28.8508 24.5955 28.5681 24.6314 28.4167C24.6822 28.178 25.1633 26.0322 25.2529 25.6378C25.31 25.3733 25.7038 25.2744 25.8401 25.6103C25.9918 26.058 28.2414 32.8012 28.3313 33.1109C28.5786 33.9626 29.4016 34.2556 29.9476 34.2556C30.6386 34.2556 31.5804 33.7211 31.2577 32.1781Z"
        fill="currentColor"
      />
    </svg>
  );
};
