import type { AllHTMLAttributes, FC } from 'react';

export const Relaxation: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 182" {...props}>
    <path
      d="M61 126.58c-22 3-36.82 6-36.86 6L7.66 71.32C6.35 67.08.48 67 1 72.8l11.15 69h57.59c2.26-4.8-.02-16.37-8.74-15.22z"
      fill="currentColor"
    />
    <path
      d="M29.92 58c-8.41 2.2-11.59 10.19-9.52 19l11.53 46.74c25.37-5.26 40.32-5.66 45.44-4.87-1.49 16.68-.76 58.77-.76 62.17h8.76l5.71-38.61s18.83 33 21.57 38.35l8-4.18L95 120.79c.35-.88 1.68-11.16-.89-13.25-4.36-3.56-39.53-1.62-39.53-1.62l-7.71-38c-.96-6.01-8.3-12.15-16.95-9.92zM13.53 44a14.06 14.06 0 105.15-19.21A14.06 14.06 0 0013.53 44z"
      fill="currentColor"
    />
    <path
      fill="currentColor"
      d="M69.45 85.93l3.77 6.59L90.66 66.8l.68-17.97-21.89 37.1z"
    />
    <path
      fill="currentColor"
      d="M69.89 55.87L56.8 82.77l12.05 6.59-1.98-3.45 1.35-2.29-6.07-3.4.72-1.3 6.12 3.41L70 80.6l-6.27-3.64.74-1.29 6.29 3.64 1.02-1.73-6.48-3.88.76-1.28 6.49 3.87 1.02-1.73-6.68-4.12.78-1.27 6.66 4.1 1.03-1.74-6.89-4.35.8-1.26 6.85 4.32 1.02-1.74-7.08-4.58.81-1.25 7.03 4.55 2.55-4.33-10.56-7.02zM116.5 1A21.5 21.5 0 10138 22.5 21.49 21.49 0 00116.5 1zm0 39.32a17.82 17.82 0 1117.82-17.82 17.82 17.82 0 01-17.82 17.82z"
    />
    <path
      fill="currentColor"
      d="M118.39 8.7h-3.68v18.1l12.67-6.84-1.75-3.23-7.24 3.91V8.7z"
    />
  </svg>
);
