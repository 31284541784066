import type { AllHTMLAttributes, FC } from 'react';

export const FloorSwitch: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 43 79" {...props}>
    <g fill="currentColor">
      <polygon points="38.2 54 21.5 70.4 4.8 54 1 57.8 21.5 78 42 57.8 38.2 54" />
      <polygon points="4.8 25 21.5 8.6 38.2 25 42 21.2 21.5 1 1 21.2 4.8 25" />
    </g>
  </svg>
);
