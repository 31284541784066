import type { AllHTMLAttributes, FC } from 'react';

export const Shop: FC = (props: AllHTMLAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1608 32.9895L36.3781 36.7976L38.1771 45.5474C38.317 46.2316 38.1064 46.9404 37.616 47.434L36.5463 48.5106L32.4988 40.7014L29.7536 43.4653L30.0326 45.8551C30.0719 46.1881 29.9563 46.5219 29.7206 46.7593L28.7452 47.7409L26.6688 44.1931L23.1439 42.104L24.1193 41.1223C24.3558 40.8842 24.6867 40.7687 25.0176 40.8082L27.3926 41.089L30.1379 38.3251L22.38 34.2513L23.4489 33.1746C23.9393 32.681 24.6443 32.469 25.3233 32.6106L34.0172 34.4205L37.7999 30.6132C38.4522 29.9575 39.5093 29.9575 40.1608 30.6132C40.8131 31.2698 40.8131 32.3338 40.1608 32.9895ZM26.1577 9.74627C26.1577 8.43788 27.2156 7.37313 28.5155 7.37313H36.3747C37.6746 7.37313 38.7325 8.43788 38.7325 9.74627V17.6567H26.1577V9.74627ZM41.0903 17.6567V9.74627C41.0903 7.1287 38.9753 5 36.3747 5H28.5155C25.9148 5 23.7999 7.1287 23.7999 9.74627V17.6567H14.3822L13.0037 53.0678C12.8991 55.7598 15.0392 58 17.7153 58C37.3319 58 47.2195 58 47.3781 58C50.0743 58 52.1013 55.7455 51.9961 53.0702C51.9961 53.0678 50.4644 18.8304 50.4644 17.6567C49.9135 17.6567 46.7888 17.6567 41.0903 17.6567Z"
        fill="currentColor"
      />
    </svg>
  );
};
