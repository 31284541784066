import type { AllHTMLAttributes, FC } from 'react';

export const TransferDesks: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 26 26" aria-hidden="true" {...props}>
    <circle fill="transparent" strokeWidth="1" cx="13" cy="13" r="12" />
    <path
      d="M8.9464 15.556c.4286.1374 1.0617.2044 1.165-.0173.1122-.2404-.508-.866-.9022-1.0492 0 0-1.1383-.5264-1.14-.532l-.2356-2.6739-.5595-.2611-.4404 2.359-1.6004-.746-.0919-1.0777-.4818-.224-.449.9625c-.0643.1381-.2338.391-.1582.5429.4072.763 2.3303 1.5003 2.3337 1.5017l-1.53 1.8685.5593.2606 2.193-1.5247c-.0086.0005 1.0718.5222 1.0807.5271v.1686a.2484.2484 0 10.2574.2396zM11.472 5.2844L9.269 6.81l-1.1312-.5274c-.3888-.1814-1.2689-.2618-1.3843-.0142-.1208.2594.534.8224.923 1.0037l1.1311.5274.2529 2.6567.5653.2638.4297-2.3672c1.6464.7678 1.873.8735 2.645.8175.1788-.0456.2527-.327.3183-.4683l.4508-.9657-.4832-.2255-.89.6175-1.5905-.7412 1.5314-1.8392zM14.5765 19.9822c-.1277.012-.286.0187-.4157.0236a6.5194 6.5194 0 01-5.4676-2.6393l-.4593.3634a7.1612 7.1612 0 00.7146.8212 7.0788 7.0788 0 005.233 2.0399 6.8942 6.8942 0 00.395-.0236.3865.3865 0 00-.223.1148l-1.0999 1.103.8277-.0006 1.546-1.55-1.548-1.548-.828.0005 1.1014 1.1012a1.1454 1.1454 0 00.2238.194zM15.1609 6.5011a1.382 1.382 0 00.2557-.1223l1.376-.7311-.7916-.2425-1.933 1.0279 1.0253 1.9348.7908.2424-.7287-1.3777a.3868.3868 0 00-.1802-.1737 6.5165 6.5165 0 012.6344 11.8113l.375.4621A7.1034 7.1034 0 0015.161 6.5011z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
