import type { AllHTMLAttributes, FC } from 'react';

export const CashChange: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 182" {...props}>
    <polygon
      points="215.085 165.329 10.017 129.168 27.039 32.614 19.368 31.257 1 135.492 213.731 173 215.085 165.329"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M246.38568,29.45126a29.78284,29.78284,0,1,0,24.15888,34.50229A29.78283,29.78283,0,0,0,246.38568,29.45126ZM238.089,75.57894a34.17455,34.17455,0,0,1-8.41421-1.12731l.13707-6.7819a17.31294,17.31294,0,0,0,7.65115,1.71c3.04457,0,6.32425-1.04272,6.32425-5.04062,0-3.50658-3.63159-4.581-6.96223-4.581a27.10969,27.10969,0,0,0-6.83718.76144l.23121-16.854h20.45631v5.667H237.40808v5.207c1.17018-.08219,1.93323-.17593,2.4734-.17593,6.38678,0,12.00292,2.796,12.00292,9.70538C251.88441,72.83541,245.10211,75.57894,238.089,75.57894Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M199.7242,8.68389a22.12113,22.12113,0,1,0,4.77853,42.16105,36.3639,36.3639,0,0,1,13.48609-21.34081A22.14181,22.14181,0,0,0,199.7242,8.68389Zm.771,34.32437h-6.1367V26.10139l-4.35976,3.1094-2.7471-4.16417,7.827-5.08155h5.41653Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M117.94767,110.65631C126.73575,112.21,129.115,98.215,130.41824,90.85755c1.32689-7.53569,3.82-21.6754-4.88395-23.20948-8.5356-1.51244-11.03028,12.63689-12.35517,20.1626C111.8835,95.17809,109.32791,109.14187,117.94767,110.65631Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M233.6627,95.64778a36.59629,36.59629,0,0,1-30.23231-36.23392,28.63728,28.63728,0,0,1-12.76637.58506,29.07231,29.07231,0,0,1-19.971-13.985L36.81714,22.43809,19.118,122.79227l209.83665,36.99536,11.22026-63.63892A35.42519,35.42519,0,0,1,233.6627,95.64778ZM81.79591,112.22205l-11.71917-2.06667,7.95247-45.10579-11.29274,6.293-4.51809-8.651,19.4954-10.70767,10.373,1.82582Zm60.34349-19.2982c-2.39083,13.55661-8.81893,29.41836-25.71579,26.43637C98.43266,116.18629,99.3091,97.96453,101.458,85.74443c2.58881-14.64266,9.79956-29.58669,25.61039-26.80029C144.14316,61.9498,144.51907,79.36724,142.1394,92.92385Zm54.05855,9.5299c-2.39128,13.55661-8.80926,29.41837-25.71618,26.444-17.9905-3.17393-17.11411-21.39569-14.95359-33.61384,2.57877-14.64264,9.78791-29.59868,25.6104-26.81183,17.06319,3.01717,17.4467,20.423,15.05942,33.98167Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M172.01585,120.19379c8.79009,1.54609,11.16573-12.44133,12.47258-19.80674,1.31528-7.53733,3.82-21.67744-4.88395-23.21152-8.53562-1.50281-11.0403,12.6389-12.35517,20.17463C165.94206,104.708,163.39809,118.67134,172.01585,120.19379Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
