import type { AllHTMLAttributes, FC } from 'react';

export const Hotel: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="25.9084" cy="48.2627" r="8.63614" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.31807 35C6.70288 35 8.63614 36.9333 8.63614 39.3181V75.4048H119.364V60.2916C119.364 57.9068 121.297 55.9735 123.682 55.9735C126.067 55.9735 128 57.9068 128 60.2916V93.294H119.364V84.041H8.63614V93.294H0V39.3181C0 36.9333 1.93327 35 4.31807 35ZM42.5639 43.3277C44.9487 43.3277 46.8819 45.261 46.8819 47.6458V69.853H17.8892C15.5044 69.853 13.5711 67.9197 13.5711 65.5349C13.5711 63.1501 15.5044 61.2169 17.8892 61.2169H38.2458V47.6458C38.2458 45.261 40.1791 43.3277 42.5639 43.3277Z"
      fill="currentColor"
    />
    <path
      d="M51.2 47.6458V69.853H112.887V64.5358C112.634 58.2802 104.978 53.1715 101.182 52.3375L51.2 47.6458Z"
      fill="currentColor"
    />
  </svg>
);
