import type { AllHTMLAttributes, FC } from 'react';

export const PublicTransportInfo: FC = (
  props: AllHTMLAttributes<SVGElement>,
) => (
  <svg aria-hidden="true" viewBox="0 0 78 78" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        style={{ fill: 'var(--color-info, blue)' }}
        d="M20.9 0v20.9H0v56.867h77.767V0z"
      />
      <path
        d="M37.932 24.714c0-.954.328-1.78.984-2.475.656-.696 1.48-1.044 2.475-1.044.955 0 1.75.348 2.386 1.044a3.55 3.55 0 0 1 .954 2.475c0 1.034-.318 1.87-.954 2.505-.636.637-1.431.955-2.386.955-.994 0-1.82-.318-2.475-.955-.656-.635-.984-1.47-.984-2.505m3.489 35.848c-.684 0-1.377.01-2.08.03-.7.02-1.35.05-1.946.09a61.08 61.08 0 0 0-1.551.119c-.438.039-.716.06-.835.06 0-.041-.02-.18-.06-.418a3.568 3.568 0 0 1-.06-.537c0-.397.18-.636.537-.716 1.034-.198 1.87-.437 2.506-.716.635-.277.954-.735.954-1.371V40.879c0-1.193-.209-2.048-.626-2.565-.418-.516-1.323-.835-2.714-.955-.517-.079-.776-.357-.776-.835 0-.198.04-.456.12-.775.516 0 1.242-.03 2.177-.09.933-.06 1.702-.128 2.302-.208a34.997 34.997 0 0 0 2.472-.507c.724-.18 1.246-.269 1.569-.269.563 0 .844.299.844.895 0 .557-.06 1.223-.179 1.998-.119.776-.179 1.8-.179 3.072v16.463c0 .636.308 1.094.925 1.371.616.279 1.48.518 2.595.716.317.08.477.319.477.716 0 .12-.01.298-.03.537-.02.238-.05.377-.09.417-.119 0-.387-.02-.805-.06a51.654 51.654 0 0 0-3.469-.208c-.702-.02-1.394-.03-2.078-.03"
        fill="#FFF"
      />
    </g>
  </svg>
);
