import type { AllHTMLAttributes, FC } from 'react';

export const Checkmark: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 82 64" {...props}>
    <path
      fill="currentColor"
      d="M26.15 50L7.25 30.65L0.5 37.4L26.15 63.5L81.5 7.25L74.75 0.5L26.15 50Z"
    ></path>
  </svg>
);
