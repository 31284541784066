import type { AllHTMLAttributes, FC } from 'react';

export const Restaurant: FC = (props: AllHTMLAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.2683 5.00007C42.7218 4.99578 42.1927 5.19223 41.7813 5.55214C41.37 5.91206 41.105 6.41041 41.0367 6.95269L37.271 32.6158V33.1737C37.2703 34.1857 37.6626 35.1584 38.365 35.8869C39.0675 36.6154 40.0254 37.0428 41.0367 37.0789H42.0131V56.3262C42.0131 56.546 42.0563 56.7636 42.1405 56.9667C42.2246 57.1698 42.3479 57.3543 42.5033 57.5097C42.6587 57.6651 42.8432 57.7884 43.0462 57.8725C43.2493 57.9566 43.4669 57.9999 43.6867 57.9999H43.8262C44.2701 57.9999 44.6958 57.8236 45.0097 57.5097C45.3236 57.1958 45.4999 56.7701 45.4999 56.3262V56.3262V7.23164C45.4999 6.63979 45.2648 6.07218 44.8463 5.65368C44.4278 5.23518 43.8602 5.00007 43.2683 5.00007V5.00007Z"
        fill="currentColor"
      />
      <path
        d="M30.1579 17.2736C30.1579 17.7175 29.9815 18.1432 29.6677 18.4571C29.3538 18.771 28.9281 18.9473 28.4842 18.9473V18.9473C28.2642 18.9664 28.0427 18.9418 27.8323 18.8749C27.6219 18.808 27.4268 18.7002 27.2583 18.5576C27.0898 18.415 26.9511 18.2404 26.8503 18.044C26.7495 17.8476 26.6886 17.6332 26.671 17.4131V17.4131V5H24.5789V17.2736C24.5789 17.7175 24.4026 18.1432 24.0887 18.4571C23.7748 18.771 23.3491 18.9473 22.9052 18.9473V18.9473C22.6853 18.9664 22.4638 18.9418 22.2534 18.8749C22.043 18.808 21.8479 18.7002 21.6794 18.5576C21.5108 18.415 21.3722 18.2404 21.2714 18.044C21.1706 17.8476 21.1097 17.6332 21.0921 17.4131V17.4131V5H19V19.6447C19.0651 21.0509 19.5719 22.4009 20.4483 23.5026C21.3246 24.6043 22.526 25.4017 23.8816 25.7815V56.1867C23.8625 56.4066 23.8871 56.6282 23.954 56.8385C24.0208 57.0489 24.1287 57.244 24.2713 57.4126C24.4139 57.5811 24.5884 57.7197 24.7849 57.8205C24.9813 57.9213 25.1957 57.9823 25.4158 57.9998V57.9998C25.8887 58.0047 26.3474 57.8377 26.7065 57.5298C27.0657 57.222 27.3008 56.7943 27.3684 56.3261V56.3261V25.7815C28.6704 25.4443 29.8372 24.7147 30.7105 23.6917C31.5838 22.6688 32.1212 21.4019 32.25 20.0631V5H30.1579V17.2736Z"
        fill="currentColor"
      />
    </svg>
  );
};
